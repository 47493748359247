<template>
    <div>
        <div class="modal fade" id="formModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true" @click="askOnClose">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="modalSize ? modalSize : 'modal-lg'" :style="modalWidth ? {maxWidth: modalWidth} : {}">
                <div class="modal-content" :style="{height: (modalHeight ? modalHeight : 'auto')}">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <span v-show="isMail">Bericht versturen</span>
                            <span v-show="!isMail && !itemId && !isCopy">{{$t('form.add', [moduleName])}}</span>
                            <span v-show="!isMail && !itemId && isCopy">{{$t('form.copy', [moduleName])}}</span>
                            <span v-show="!isMail && itemId">{{$t('form.edit', [moduleName])}}</span>
                            <span id="detailsSpan"></span>
                        </h5>
                        
                        <button id="modal-close-button" type="button" class="d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        <button type="button" id="fakeCloseBtn" class="btn-close"></button>
                    </div>
                    <div class="modal-body">

                        <div :class="{ 'd-none': !loading }">
                            <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}}
                        </div>
                        <form :class="{ 'd-none': loading }" autocomplete="off" @keydown="preventSendOnEnter">
                            <slot></slot>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <slot name='modal-footer'></slot>   
                        <button id="savebutton" type="button" class="btn btn-success" @click.prevent="save">{{$t('form.save')}}</button>
                       
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{$t('form.delete') + ' ' + moduleName.toLowerCase()}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {{$t('form.are_you_sure_delete')}} <b>{{itemName}}</b>?
                    </div>
                    <div class="modal-footer d-flex">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('form.no')}}</button>
                        <button type="button" class="btn btn-danger" @click.prevent="del">{{$t('form.yes')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormPopup',
        emits: ['save', 'del'],
        props: ['moduleName', 'itemId', 'itemName', 'loading', 'modalSize', 'modalHeight', 'modalWidth', 'isCopy', 'isMail'],
        data() {
            return {
                shouldAskOnClose: false
            }
        },
        methods: {
            save() {
                this.shouldAskOnClose = false;
                this.$emit("save");
            },
            del() {
                this.shouldAskOnClose = false;
                this.$emit("del");
            },
            preventSendOnEnter(e){
                if(e.key == 'Enter' && document.activeElement.tagName !== 'TEXTAREA') e.preventDefault();
            },
            askOnClose(e){
                //Ask if sure when user wants to close modal - don't ask if nothing (except tabs) was clicked yet.
                if(this.shouldAskOnClose){
                    if( (e.target.id == "formModal" || e.target.id == "fakeCloseBtn") && confirm(this.$t('form.are_you_sure_close'))){
                        document.querySelector("#modal-close-button").click();
                        this.shouldAskOnClose = false;
                    }
                }
                else if( (e.target.id == "formModal" || e.target.id == "fakeCloseBtn") ){
                    document.querySelector("#modal-close-button").click();
                }
                else if( !e.target.classList.contains('nav-link') && e.target.id != 'modal-close-button'){
                    this.shouldAskOnClose = true;
                }
            }
        }
    }

</script>

<style>
    .nav-tabs{
        border: none
    }
    .nav-link {
        font-weight: bold
    }
    .nav-link.active {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid #0d6efd !important;
        border-radius: 0px;
        color: black !important
    }
    .nav-link:not(.active) {
        border: none;
        color: gray
    }
    .clickable {cursor: pointer}
    .v-toast { z-index: 1060 !important }
    .required-label::after{
        color: #d00;
        content: ' *'
    }
    .far.fa-close{
        position: relative;
        bottom: 2px
    }
</style>